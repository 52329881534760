<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="任务状态">
          <el-radio-group v-model="condition.dealState" size="small" @change="getPageData()">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="0">待完成</el-radio-button>
            <el-radio-button label="1">已完成</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="责任部门">
          <l-select v-model="condition.departId" :remote="$api.departList"></l-select>
        </el-form-item>
        <el-form-item label="完成时间">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" size="small" width="300" placeholder="项目名称/责任人名称"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="warning" size="small" @click="doExport()">导出</el-button>
        </el-form-item> -->
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="taskName" slot-scope="scope">
          <el-button type="text" @click="$site.view_safely_regular_task_detail(scope.row.taskId)">{{ scope.row.taskName }}</el-button>
        </template>
        <template slot="matterStatusName" slot-scope="scope">
          <span v-if="scope.row.matterStatus == 0" class="text-grey">未提交</span>
          <span v-else-if="scope.row.matterStatus == 1" class="text-blue">已提交</span>
          <span v-else-if="scope.row.matterStatus == 10" class="text-green">已入围</span>
          <span v-else-if="scope.row.matterStatus == 9" class="text-red">无效建议</span>
        </template>
        <template slot="effectStatusName" slot-scope="scope">
          <span v-if="scope.row.effectStatus == 0" class="text-grey">---</span>
          <span v-else-if="scope.row.effectStatus == 1" class="text-blue">实施中</span>
          <span v-else-if="scope.row.effectStatus == 2" class="text-green">已完成</span>
          <span v-else-if="scope.row.effectStatus == 8" class="text-red">无法实施</span>
          <span v-else-if="scope.row.effectStatus == 9" class="text-red">暂缓实施</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'AdviceList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '任务编号', prop: 'taskNo', overflow: true },
          { label: '任务名称', slot: 'taskName', overflow: true },
          { label: '负责部门', prop: 'departName' },
          { label: '负责人', prop: 'userName' },
          { label: '实施部门', prop: 'effectDepartName' },
          { label: '实施人', prop: 'effectUserName' },
          { label: '完成人', prop: 'dealUserName' },
          { label: '任务开始时间', prop: 'startTime' },
          { label: '任务结束时间', prop: 'endTime' },
          { label: '实际完成时间', prop: 'dealTime' }
        ]
      },
      condition: {
        // enterpriseCode: this.userInfo.enterpriseCode,
        departId: '',
        startDate: '',
        endDate: '',
        keyword: '',
        dealState: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/safely/regular/task/pagedlist', ['enterpriseCode', this.condition])
    },
    doExport() {
      const h = this.$createElement

      this.$lgh.get('api/enterprise/safely/matter/export', ['enterpriseCode', this.condition]).then((res) => {
        this.$lgh.msgSuccess(h('a', { attrs: { target: '_blank', href: res.link_url } }, '点击下载'))
      })
    }
  }
}
</script>
